import { makeObservable, observable, action, flow, computed } from 'decorators';
import api from 'services/api';
import log from 'services/log';
import auth from 'services/auth';
import { installScript } from 'utils/dom';

export const StipeApiVersion = '2024-10-28.acacia';

let loadingPromise;
export function getStripe() {
  if (!loadingPromise) {
    loadingPromise = installScript('https://js.stripe.com/v3/')
      .catch((err) => {
        loadingPromise = null;
        throw err;
      });
  }
  return loadingPromise;
}

export default class StripePaymentStore {
  @observable checkout = null;
  @observable isCompleting = false;

  constructor(id, session, onReturn) {
    makeObservable(this);
    this._id = id;
    this._session = session;
    this._onReturn = onReturn;
    this._init().done();
  }

  @computed get orgId() {
    return this._session.orgId;
  }

  @computed get isCardOnly() {
    return !this._session.amountDue;
  }

  cancel() {
    this._onReturn();
  }

  dispose() {
    if (this.checkout) { this.checkout.destroy(); }
  }

  @flow *_init() {
    try {
      yield getStripe();
      this._stripe = window.Stripe(this._session.appId, { stripeAccount: this._session.userId, apiVersion: StipeApiVersion });
      this.checkout = yield this._stripe.initEmbeddedCheckout({
        fetchClientSecret: async() => {
          const res = await api.postAnon(`paymentsession/${this._id}/stripe`);
          const secret = res.data.clientSecret;
          // If there is no secret, we are probably done!
          if (!secret) { this._onReturn(); }
          return secret;
        },
        onComplete: action(() => {
          this.isCompleting = true;
          this._pollComplete();
        })
      });
    } catch (err) {
      log.error('Could not init Stripe on checkout', err);
      auth.setNotValidLink('Payment Session', 'Unable to load the payment page due to an issue with the payment processor. Please try again later.');
    }
  }

  async _pollComplete() {
    const res = await api.getAnon('paymentsession/' + this._id);
    if (res.data.isComplete) { this._onReturn(); return; }

    setTimeout(() => this._pollComplete(), 5000);
  }
}